import { useCallback } from 'react';

import { useRecoilState } from 'recoil';
import { UserLocationState } from 'states/use-location';

/* 
 This hooks help us to get user Location info 
 */
export const useLocation = () => {
	const [locationinfo, setLocationInfo] = useRecoilState(UserLocationState);
	const fetchLocation = useCallback(
		() => {
			if (!navigator.geolocation) {
				// eslint-disable-next-line no-console
				console.warn("Geolocation is not supported")
				return;
			}
			navigator.geolocation.getCurrentPosition(
				(info) => {
					/*
              setting only few values as Other is not required  if coords is available
            */
					if (info?.coords) {
						const {
							accuracy,
							altitude,
							altitudeAccuracy,
							latitude,
							longitude,
						} = info.coords;
						setLocationInfo({
							accuracy,
							altitude,
							altitudeAccuracy,
							latitude,
							longitude,
						});
					}
				},
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				() => ({}),
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
			)
		},
		[setLocationInfo]
	);
	if (!locationinfo) fetchLocation();
	return { fetchLocation };
};
