import { FC, useCallback, useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import { Button, Loader } from '@storybook';
import {
	ActiveRecipientTabState,
	AddRecipientFormBtn,
	prepareTypeState,
	templateTypeState,
} from 'views';
import { GetQueryParams } from 'utils';

import { RecipientLocalState } from '../store';

/**
 * @Manish Define the props that the AddRecipientFooter component accepts
 * */
interface IAddRecipientFooter {
	primaryBtnLabel: string | JSX.Element;
	secondaryBtnLabel?: string;
	handlePrimaryClick: () => Promise<void | boolean> | void;
	handleSecondaryClick?: () => void;
	loading?: boolean
	isDisabled?:boolean;
}

/**
 * Create the AddRecipientFooter functional component
 * */
export const AddRecipientFooter: FC<IAddRecipientFooter> = ({
	primaryBtnLabel,
	secondaryBtnLabel,
	handlePrimaryClick,
	handleSecondaryClick,
	loading,
	isDisabled
}) => {

	const activeTab = useRecoilValue(ActiveRecipientTabState);
	/**
	 * Get the local recipients from Recoil state
	 * */
	const localRecipients = useRecoilValue(RecipientLocalState);

	/**
	 * Get template type from Recoil state
	 * */
	const templateType = useRecoilValue(templateTypeState);

	/**
	 * Get prepare type from Recoil state
	 * */
	const prepareType = useRecoilValue(prepareTypeState);
	const templateId = GetQueryParams('templateId');

	/**
	 * Determine if the primary button should be disabled
	 * */
	const isPrimaryButtonDisabled = useMemo(
		() => !localRecipients.length,
		[localRecipients]
	);

	const isRecipientButtonDisabled = useMemo(()=>{
		return loading || isPrimaryButtonDisabled || isDisabled
	},[isDisabled, isPrimaryButtonDisabled, loading])

	/**
	 * Handle the primary button click with loading state
	 * */
	const handlePrimaryButtonClicked = useCallback(async () => {
		await handlePrimaryClick();
	}, [handlePrimaryClick]);

	/**
	 * Determine the label for the primary button (loading or primaryBtnLabel)
	 * */
	const _primaryButtonLabel = useMemo(
		() =>
			!loading ? (
				primaryBtnLabel
			) : (
				<Loader dimension={20} className={'loader-white'} type='circle' />
			),
		[loading, primaryBtnLabel]
	);

	const renderAddRecipientButton = useMemo(() => {
		/**
     * Condition 1: Check if prepareType is 'overlay' and templateType is 'overlay'.
     *                      or
     * Condition 2: Check if templateType is 'basic'.
     *                      or
     * Condition 3: Check if templateId exists.
     * */ 
		if (
			((prepareType === 'overlay' && templateType === 'overlay') ||
			templateType === 'basic' ||
			(!!templateId ) ) && activeTab === "workflow"
		) {
			/**
       * If any of the conditions is met, render an "Add Recipient" button.
       * */ 
			return <AddRecipientFormBtn />;
		}
		/**
     * If none of the conditions is met, don't render anything.
     * */ 
		return <></>;
	}, [activeTab, prepareType, templateId, templateType]);

	return (
		<div className="add-recipient__footer">
			<div>{renderAddRecipientButton}</div>
			<div className="add-recipient__btn-container">
				{secondaryBtnLabel && (
					/**
					 * Render a secondary button if secondaryBtnLabel is provided
					 **/
					<Button
						className="esign-button__filled esign-button__filled--secondary esign-button__large prepare-dashboatrd__cancel-btn"
						handleClick={handleSecondaryClick}
						label={secondaryBtnLabel}
					/>
				)}
				<Button
					className="esign-button__filled esign-button__filled--primary esign-button__large prepare-dashboatrd__continue-btn"
					handleClick={handlePrimaryButtonClicked}
					disabled={isRecipientButtonDisabled}
					label={_primaryButtonLabel}
				/>
			</div>
		</div>
	);
};
