import {
	FC,
	HTMLInputTypeAttribute,
	useMemo,
} from 'react';

import { IInputChange } from 'types';

interface IInputField {
	value: string | undefined;
	handleChange: (id: string, e: IInputChange) => void;
	id: string;
	label: string;
	name: string;
	type: HTMLInputTypeAttribute;
	className: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	style?: any;
	maxLength?: number;
}

export const InputField: FC<IInputField> = ({
	value,
	handleChange,
	id,
	label,
	className,
	type,
	style,
	maxLength,
	name
}) => {
	const { fontSize, fontFamily, color } = style ?? {};
	const getMinHeight = useMemo(() => {
		const { value } = fontSize ?? {};
		if (value) {
			const fontSizeNumber = parseInt(`${value}`.replace('px', ''));
			return fontSizeNumber + 4;
		}
		return 12;
	}, [fontSize]);

	const isDisabled = /fullName|email/.test(name);

	return (
		<div className={className} style={{ minHeight: getMinHeight }}>
			<input
				type={type}
				onChange={(e) => handleChange(id, e)}
				value={value}
				placeholder={`Enter ${label}`}
				maxLength={maxLength}
				disabled={isDisabled}
				style={{
					fontSize: fontSize?.value,
					color,
					fontFamily: fontFamily?.value,
				}}
			/>
		</div>
	);
};
