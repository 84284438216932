import { CSSProperties, FC, Fragment, useMemo } from 'react';

import { Resizable } from 're-resizable';
import { useRecoilValue } from 'recoil';
import {
	DROP_ITEM_SOURCE,
	DragTypes,
	DraggableBox,
	IDropedItems,
	SelectedConfigNode,
	useCoordinates,
	useDropItem,
	useNodeType,
} from 'views';
import { EnvelopePurposeState } from 'states';
import { useSelfSign } from 'views/self-sign/store';
import { DarkenHexColor } from 'utils';
import { APPEARANCE_CONFIG_KEYS, CHECKBOX_TO_TEXT_SIZE_MAP } from 'views/config-items/components/checkbox-configuration-component';

interface IDropItemProps {
	item: IDropedItems;
	index: number;
	pageId: string;
	pageWidth: number;
	onlyNode?: boolean;
	pageHeight: number;
	customStyle?: CSSProperties
}

export const DropItem: FC<IDropItemProps> = ({
	item,
	index,
	pageId,
	onlyNode = false,
	pageWidth,
	pageHeight,
	customStyle = {},
}) => {
	const activeNode = useRecoilValue(SelectedConfigNode);
	const { getInputForFieldNode } = useNodeType(); // for the added field nodes
	const { handleResize, getResizeBound } = useCoordinates();
	const { getSelfSignInputForFieldNode } = useSelfSign();
	const purpose = useRecoilValue(EnvelopePurposeState);

	const {
		handleSelectNode,
		onlyNodeContainerStyles,
		onlyNodeInnerStyles,
		dropItemStyles,
		resizeHandlers,
		itemProperties,
		handleDeleteDropItem,
	} = useDropItem(item, index);

	const getBackgroundColor = useMemo(() => {
		if (item?.source==='common') {
			return '#e4e6eb'
		}
		if (
			purpose === 'selfsign' &&
			(item.fieldType === 'signature' || item.fieldType === 'initial') &&
			item.value
		) {
			return 'transparent';
		}
		return dropItemStyles.background;
	}, [dropItemStyles.background, item.fieldType, item.value, purpose, item.source]);

	const updatedDropItemsStyles = {
		...dropItemStyles,
		background: getBackgroundColor,
	};

	const updatedOnlyNodeInnerStyles = {
		...onlyNodeInnerStyles,
		background: getBackgroundColor,
	};

	const showCheckboxValue = useMemo(() =>{
		return (item.source === DROP_ITEM_SOURCE.questionaire && 
				item.fieldType === "checkbox" && 
				(item.metadata.checkboxAppearance === APPEARANCE_CONFIG_KEYS.checkboxWithValue || 
					item.metadata.checkboxAppearance === APPEARANCE_CONFIG_KEYS.onlyOptionVaue)
			)
	},[item]);


	const hideCheckbox = useMemo(() =>{
		return (
			item.source === DROP_ITEM_SOURCE.questionaire && 
			item.fieldType === "checkbox" && 
			item.metadata.checkboxAppearance === APPEARANCE_CONFIG_KEYS.onlyOptionVaue
		)
	},[item]);

	const renderCheckboxValue = useMemo(() =>{
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const fontSize = (item?.metadata?.fontSize as any)?.value;
		const lineHeight = parseInt(fontSize?.toString().replace("px", "")) - 2;
		return <p 
			className='question-checkbox-label'
			style={{
				fontSize: fontSize ? CHECKBOX_TO_TEXT_SIZE_MAP[fontSize] : '10px',
				lineHeight: lineHeight+"px",
			}}>
				{item.metadata.checkboxValue}
			</p>
	},[item]);


	if (onlyNode) {
		const textClass = item.fieldType === 'text' ? 'resize--text' : ''
		return (
			<>
				<div
					className="custom-drag-item-outer drop-item"
					style={{ 
						...onlyNodeContainerStyles,
						width: 'fit-content'
					}}
				>
					{!hideCheckbox && <div
						className={`custom-drag-item resize ${textClass}`}
						style={updatedOnlyNodeInnerStyles}
					>
						{purpose === 'selfsign'
							? getSelfSignInputForFieldNode(
									item.fieldType,
									item,
									pageWidth,
									pageHeight,
									index
						)
							: getInputForFieldNode(item.fieldType, item, itemProperties.minWidth)}
					</div>}
					{ showCheckboxValue && renderCheckboxValue}
				</div>
			</>
		);
	}

	const getBorderColor = useMemo(() => {
		if (item?.source === 'common') {
			return '#e4e6eb';
		} else {
			return dropItemStyles.background;
		}
	}, [item?.source, dropItemStyles?.background]);

	const isCheckbox = useMemo(() => item.fieldType === "checkbox", [item])

	const getborderWidth = useMemo(() =>{
		return item.fieldType === "checkbox" ? 1 : 2;
	},[item.fieldType])

	


	return (
		<DraggableBox
			className="drop-item"
			handleClick={handleSelectNode}
			styles={{...updatedDropItemsStyles, ...customStyle}}
			hideSourceOnDrag={true}
			data={item}
			type={DragTypes.DroppedItem}
			showEmptyPlaceholder={true}
			tabIndex={1}
			handleKeyDown={handleDeleteDropItem}
		>
			<>
			{!hideCheckbox && <Resizable
				enable={resizeHandlers}
				size={{
					width: itemProperties.width,
					height: itemProperties.height,
				}}
				bounds={getResizeBound(pageId)}
				lockAspectRatio={item.key === 'initial' || item.key === "signature" ? true : false}

				className={`resize resize${
					item.id === activeNode?.id ? 
						isCheckbox ? ' checbox-node-red' : '--active' 
						: ''
				}
				resize${item.fieldType==="text"?"--alignement":""}
				`
			}
				maxHeight={itemProperties.maxHeight}
				maxWidth={itemProperties.maxWidth}
				minHeight={itemProperties.minHeight}
				minWidth={itemProperties.minWidth}
				onResizeStart={(
					e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
				) => {
					e.preventDefault();
					e.stopPropagation();
				}}
				style={{
					boxSizing: 'border-box',
					MozBoxSizing: 'border-box',
					WebkitBoxSizing: 'border-box',
					minWidth: 'fit-content',
					display: 'flex',
					alignItems: 'center',
					border: `${getborderWidth}px solid ${DarkenHexColor(getBorderColor as string,
						1
					)}`,

				}}
				onResizeStop={(
					_event: MouseEvent | TouchEvent,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					_direction: any,
					refToElement: HTMLElement
				) => {
					handleResize(refToElement, item, pageId);
				}}
				handleStyles={{
					bottomRight: {
						fontSize: '20px',
						right: '-6px',
						bottom: '-6px',
					},
					bottomLeft: {
						left: '-6px',
						bottom: '-6px',
					},
					topLeft: {
						left: '-6px',
						top: '-6px',
					},
					topRight: {
						right: '-6px',
						top: '-6px',
					},
				}}
				handleWrapperClass={`${
					item.id === activeNode?.id ? 'resize-handle' : ''
				}`}
			>
				<Fragment>
					{purpose === 'selfsign'
						? getSelfSignInputForFieldNode(
								item.fieldType,
								item,
								pageWidth,
								pageHeight,
								index
					)
					:  getInputForFieldNode(item.fieldType, item, itemProperties.minWidth)}
				</Fragment>
			</Resizable>}
			{ showCheckboxValue && renderCheckboxValue}
			</>
		</DraggableBox>
	);
};
